import styled from 'styled-components'
import { Text } from 'rebass'

const StyledServicesList = styled(Text)`
  ul {
    column-count: 1;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.625rem;
    list-style: none;
    margin-top: 0;
    padding-left: 0;

    @media (min-width: ${props => props.theme.breakpoints[1]}) {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }

    @media (min-width: ${props => props.theme.breakpoints[2]}) {
      column-count: 2;
    }

    li {
      padding-left: 20px;
      position: relative;
    }

    li::before {
      background-color: #FF7A7A;
      border-radius: 50%;
      content: '';
      left: 0;
      height: 5px;
      top: calc(50% - 2.5px);
      position: absolute;
      width: 5px;
    }
  }
`

export default StyledServicesList
