import React from 'react'
import Img from 'gatsby-image'
import LazyLoad from 'react-lazy-load'
import { Parallax } from 'react-scroll-parallax'
import {
  Flex, Box, Text, Image
} from 'rebass'
import Loadable from 'react-loadable'

import ScrollAnimationWrapper from '../../elements/ScrollAnimationWrapper'
import FadeIn from '../../elements/FadeIn'
import Heading from '../../elements/Heading'
import LiveUrl from '../CaseStudyLiveUrl'
import QuickFacts from '../CaseStudyQuickFacts'
import ServicesList from '../CaseStudyServices'
import TheChallengeBgImage from '../CaseStudyChallengeBg'
import TheSolution from '../CaseStudySolution'
import RelativeBox from '../../elements/RelativeBox'
import TheSolutionBgImage from '../CaseStudySolutionBg'
import DropShadowGatsbyImage from '../../elements/DropShadowGatsbyImage'
import StyledCaseStudyPage from './style'
import useScreenWidth from '../../../hooks/useScreenWidth'

const CaseStudyContent = ({ content }) => {
  const {
    bodyContentPath,
    className: caseStudyClassName,
    desiredOutcomesCollage,
    heroImage,
    heroCollage,
    icon,
    logo,
    quickFactTitle,
    subheading,
    theChallengeImage,
    theSolutionBackground,
    theSolutionImage,
    theSolutionImage2,
    theSolutionGallery,
    title
  } = content

  const desiredOutcomeText = content.desiredOutcomeText.childMarkdownRemark.html
  const liveUrl = content.liveUrl && content.liveUrl.childMarkdownRemark.html
  const quickFactDetail = content.quickFactDetail && content.quickFactDetail.childMarkdownRemark.html
  const services = content.services.childMarkdownRemark.html
  const theChallengeText = content.theChallengeText && content.theChallengeText.childMarkdownRemark.html
  const theSolutionText = content.theSolutionText.childMarkdownRemark.html


  /* eslint-disable prefer-template */
  /* (eslint doesn't like template literals in dynamic imports) */
  const LoaderRouter = Loadable({
    loader: () => import('../CaseStudyCustom' + bodyContentPath + '/index.js'),
    loading() {
      return <div>Loading...</div>
    }
  })

  const screenWidth = useScreenWidth()
  const mobileImage = !!screenWidth && screenWidth < 767
  const animOn = !!screenWidth && screenWidth >= 992

  return (
    <ScrollAnimationWrapper refreshDuration={1500}>
      <StyledCaseStudyPage className={caseStudyClassName}>
        <Box className="content" mx={[30, 30, 'auto', 'auto']}>
          <Flex
            flexDirection={['column-reverse', null, null, 'row']}
            flexWrap="wrap"
            justifyContent="space-between"
            mt="150px"
            className="hero-section"
          >
            <Box width={[1, 1, 1, 7 / 16]}>
              <Flex flexDirection="column" justifyContent="center" alignItems="center" className="hero-section-copy">
                <Box className="case-study-logo" width={1} mb="3rem">
                  <Image alt={logo.description} mx={0} src={logo.file.url} />
                </Box>
                <Box width={1}>
                  <Heading postsymbol="" is="h1" mb={5}>
                    {title}
                  </Heading>
                </Box>
                <Box width={1}>
                  <Text fontSize={[1, 1, 2, 2]} fontWeight="300" lineHeight={['30px', '30px', '40px', '40px']}>
                    {subheading}
                  </Text>
                </Box>
              </Flex>
            </Box>
            <Box pt={[0]} width={[1, 1, 1, 8 / 16]} alignSelf="center">
              <Box data-aos="fade-in-load" ml="auto" mr={['auto', 'auto', 'auto', 0]} mb={[6, null, null, 0]} className="hero-section-image-container">
                {heroCollage
                  ? (
                    <div className="hero-collage">
                      {heroCollage.map(({ id, description, fluid }, index) => {
                        const delays = [0, 300, 150]
                        const parallaxValues = [['0px', '-30px'], ['-75px', '-135px']]
                        const image = (
                          <Img
                            key={id}
                            alt={description}
                            className={`hero-collage-item hero-collage-item-${index + 1}`}
                            fluid={fluid}
                          />
                        )
                        return (
                          <FadeIn delay={delays[index]} key={id}>
                            {parallaxValues[index]
                              ? <Parallax y={parallaxValues[index]}>{image}</Parallax>
                              : image
                            }
                          </FadeIn>
                        )
                      })}
                    </div>
                  ) : (
                    <Img
                      alt={heroImage.description}
                      className="hero"
                      fluid={heroImage.fluid}
                    />
                  )
                }
              </Box>
            </Box>
          </Flex>
          <div className="services-and-outcomes-wrapper">
            <Flex flexWrap="wrap" justifyContent="space-between" mt={[6, 6, 6, 8]} className="services-section">
              <Box width={[1, 1, 1, 9 / 16]} className="services-wrapper">
                <Text fontSize={['16px', '16px', 1, 1]} lineHeight={['16px', '16px', '28px', '28px']} fontWeight="900" mb={1}>Services.</Text>
                <ServicesList
                  services={services}
                />
              </Box>
              {liveUrl && (
                <Box mb={[6, 6, 6, 8]} className="live-url-wrapper" width={[1, 1, 1, 5 / 16]}>
                  <Text fontSize={['16px', '16px', 1, 1]} lineHeight={['16px', '16px', '28px', '28px']} fontWeight="900" mb={1}>See it live</Text>
                  <LiveUrl
                    liveUrl={liveUrl}
                  />
                </Box>
              )}
            </Flex>
            <Box>
              <Box mx="auto" width={[1, 1, 1, 11 / 16]} mt={[0, 0, 0, '240px']} mb={[0, 0, 0, 6]} className="desired-outcome-section">
                <FadeIn>
                  <Image alt={icon.description} mx="auto" mb={[4, 4, 6, 6]} src={icon.file.url} />
                  <Heading is="h2" fontSize={[2, 2, 4, 4]} textAlign="center" postsymbol="">The Brief.</Heading>
                </FadeIn>
                <FadeIn delay="150">
                  <Text
                    fontSize={[1, 1, 2, 2]}
                    lineHeight={['30px', '30px', '40px', '40px']}
                    fontWeight="300"
                    textAlign="center"
                    dangerouslySetInnerHTML={{ __html: desiredOutcomeText }}
                  />
                </FadeIn>
              </Box>
              {desiredOutcomesCollage && (
                <DropShadowGatsbyImage alt={desiredOutcomesCollage.description} className="collage" mt={[4, 4, 6, 6]} mx="auto" fluid={desiredOutcomesCollage.fluid} />
              )}
            </Box>
          </div>
          {theChallengeText && theChallengeImage && quickFactDetail && (
            <RelativeBox className="challenge-wrapper">
              <Flex className="challenge" flexWrap="wrap" mt={[6, 6, 8, 8]}>
                <TheChallengeBgImage
                  theChallengeImage={theChallengeImage}
                />
                <Box width={[1, 1, 1, 2 / 5]}>
                  <FadeIn>
                    <Text
                      fontSize={[1, 1, 2, 2]}
                      lineHeight={['30px', '30px', '40px', '40px']}
                      fontWeight="300"
                      dangerouslySetInnerHTML={{ __html: theChallengeText }}
                    />
                  </FadeIn>
                </Box>
                <Box width={[1, 1, 1, 3 / 5]}>
                  <QuickFacts
                    quickFactDetail={quickFactDetail}
                    quickFactTitle={quickFactTitle}
                  />
                </Box>
              </Flex>
            </RelativeBox>
          )}
          {bodyContentPath === 'BCGOV' && (
            <div className="bcgov-circles">
              <div className="bcgov-circles-inner">
                <div className="bcgov-circles-item bcgov-circles-item-1" />
                <div className="bcgov-circles-item bcgov-circles-item-2" />
                <div className="bcgov-circles-item bcgov-circles-item-3" />
              </div>
            </div>
          )}
          {theSolutionImage && theSolutionImage2 && !theSolutionGallery && (
            <RelativeBox className="solution-wrapper">
              <LazyLoad debounce offsetVertical={500} throttle={0}>
                <TheSolutionBgImage
                  theSolutionBackground={theSolutionBackground}
                />
              </LazyLoad>
              <TheSolution
                animOn={animOn}
                mobileImage={mobileImage}
                theSolutionImage={theSolutionImage}
                theSolutionImage2={theSolutionImage2}
                theSolutionText={theSolutionText}
              />
            </RelativeBox>
          )}
          {theSolutionGallery && (
            <section className="solution-gallery">
              <div className="solution-gallery-container">
                <div className="solution-gallery-upper" />
                <div className="solution-gallery-copy-container">
                  <div className="solution-gallery-copy">
                    <FadeIn>
                      <Heading color="#fff" is="h2" fontSize={[2, 2, 4, 4]} lineHeight={['30px', '30px', '50px', '50px']} postsymbol="">The Solution.</Heading>
                    </FadeIn>
                    <FadeIn delay="150">
                      <Text
                        fontSize={[1, 1, 2, 2]}
                        fontWeight="300"
                        color="#fff"
                        lineHeight={['30px', '30px', '40px', '40px']}
                        dangerouslySetInnerHTML={{ __html: theSolutionText }}
                      />
                    </FadeIn>
                  </div>
                </div>
                <div className="solution-gallery-lower" />
              </div>
              <div className="solution-gallery-images-container">
                <div className="solution-gallery-images">
                  {theSolutionGallery.map(({ id, description, file }, index) => {
                    const parallaxValues = [['15px', '-25px'], ['-20px', '-140px']]
                    const image = (
                      <Image alt={description} src={file.url} className={`solution-gallery-item solution-gallery-item-${index + 1}`} />
                    )
                    return (
                      <div key={id}>
                        {parallaxValues[index] ? <Parallax y={parallaxValues[index]}>{image}</Parallax> : image}
                      </div>
                    )
                  })}
                </div>
              </div>
            </section>
          )}
          <RelativeBox>
            <LoaderRouter animOn={animOn} mobileImage={mobileImage} />
          </RelativeBox>
        </Box>
      </StyledCaseStudyPage>
    </ScrollAnimationWrapper>
  )
}

export default CaseStudyContent
