import styled from 'styled-components'
import { Image } from 'rebass'

const StyledSolutionBgImage = styled(Image)`
  max-width: none;
  max-height: 1300px;
  position: absolute;
  z-index: -1;
  left: 50%;
  transform: translate(-50%, 0);
  top: 240px;

  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    max-height: 1500px;
    top: 0;
  }

  @media (min-width: ${props => props.theme.breakpoints[2]}) {
    max-height: none;
  }
`

export default StyledSolutionBgImage
