import styled from 'styled-components'
import { Flex } from 'rebass'

const StyledQuickFacts = styled(Flex)`
  background: #0060FF;
  border-radius: 50%;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2);
  color: #fff;
  height: 220px;
  margin: 250px auto -300px;
  padding: 10px;
  text-align: center;
  width: 220px;

  h2 {
    color: #fff;
  }

  p {
    margin: 0;
  }

  @media (min-width: ${props => props.theme.breakpoints[0]}) {
    height: 366px;
    margin: 23rem 0 -20rem auto;
    width: 366px;
  }

  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    margin: 36rem 0 -10rem auto;
  }
`

export default StyledQuickFacts
