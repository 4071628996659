import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { Image } from 'rebass'
import StyledChallengeBgImage from './style'

const TheChallengeBgImage = ({ theChallengeImage }) => (
  <StyledChallengeBgImage className="challenge-bg">
    {theChallengeImage.fluid
      ? <Img alt={theChallengeImage.description} fluid={{ ...theChallengeImage.fluid }} />
      : <Image alt={theChallengeImage.description} src={theChallengeImage.file.url} />
      }
  </StyledChallengeBgImage>
)

TheChallengeBgImage.propTypes = {
  theChallengeImage: PropTypes.shape({
    fluid: PropTypes.object.isRequired,
    description: PropTypes.string.isRequired,
    file: PropTypes.shape({
      url: PropTypes.string.isRequired
    })
  }).isRequired
}

export default TheChallengeBgImage
