import React from 'react'
import { Flex, Box, Text } from 'rebass'
import { Parallax } from 'react-scroll-parallax'
import StyledSolutionContainer from './style'
import FadeIn from '../../elements/FadeIn'
import Heading from '../../elements/Heading'
import ParallaxGatsbyImage from '../../elements/ParallaxGatsbyImage'

const TheSolution = ({
  animOn,
  theSolutionImage,
  theSolutionImage2,
  theSolutionText
}) => (
  <StyledSolutionContainer>
    <Flex flexWrap="wrap" width={1} mx="auto">
      <Box width={[1, 1, 1, 2 / 5]}>
        <FadeIn>
          <Heading color="#fff" is="h2" fontSize={[2, 2, 4, 4]} lineHeight={['30px', '30px', '50px', '50px']} postsymbol="">The Solution.</Heading>
        </FadeIn>
        <FadeIn delay="150">
          <Text
            fontSize={[1, 1, 2, 2]}
            fontWeight="300"
            color="#fff"
            lineHeight={['30px', '30px', '40px', '40px']}
            dangerouslySetInnerHTML={{ __html: theSolutionText }}
          />
        </FadeIn>
        <Parallax className="solution-1-plx" y={['250px', '-100px']} disabled={!animOn}>
          <ParallaxGatsbyImage className="solution-1" ml="auto" mr={['auto', 'auto', 'auto', 0]} image={theSolutionImage} />
        </Parallax>
      </Box>
      <Box className="solution-2-wrapper" width={[1, 1, 1, 3 / 5]} pt={[4, null, null, 6]}>
        <Parallax className="solution-2-plx" y={['-300px', '150px']} disabled={!animOn}>
          <ParallaxGatsbyImage className="solution-2" mx="auto" image={theSolutionImage2} />
        </Parallax>
      </Box>
    </Flex>
  </StyledSolutionContainer>
)

export default TheSolution
