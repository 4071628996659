import React from 'react'
import { ThemeProvider } from 'styled-components'
import Helmet from '../components/Helmet'
import Header from '../components/blocks/Header'
import Footer from '../components/blocks/Footer'
import CaseStudyContent from '../components/blocks/CaseStudyContent'
import theme from '../themes'

const CaseStudyPage = ({ pageContext }) => {
  const caseStudy = pageContext.caseStudyContent
  const { metaTitle, metaDescription, metaFeaturedImage } = caseStudy.seoMetadata

  return (
    <ThemeProvider theme={theme}>
      <Helmet
        title={metaTitle}
        description={metaDescription.metaDescription}
        metaImage={metaFeaturedImage && metaFeaturedImage.file.url}
        canonical={`https://powershifter.com/${caseStudy.path}/`}
      />
      <Header variant="transparent" hasLightBackground />
      <CaseStudyContent content={caseStudy} />
      <Footer />
    </ThemeProvider>
  )
}

export default CaseStudyPage
