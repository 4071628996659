import styled from 'styled-components'
import { Box } from 'rebass'

const StyledSolutionContainer = styled(Box)`
  max-width: 1168px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  padding-top: 700px;
  padding-right: 0;

  img {
    height: auto !important;
  }

  @media (min-width: ${props => props.theme.breakpoints[0]}) {
    p {
      padding-right: 100px;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    padding-top: 425px;
    padding-right: 0;

    p {
      padding-right: 300px;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints[2]}) {
    padding-top: 525px;

    p {
      padding-right: 0px;
    }
  }
`

export default StyledSolutionContainer
