import React from 'react'
import PropTypes from 'prop-types'
import StyledLiveUrl from './style'

const LiveUrl = ({ liveUrl }) => (
  <StyledLiveUrl
    fontSize={['16px', '16px', 1, 1]}
    lineHeight="28px"
    fontWeight="300"
    dangerouslySetInnerHTML={{ __html: liveUrl }}
  />
)

LiveUrl.propTypes = {
  liveUrl: PropTypes.string.isRequired
}

export default LiveUrl
