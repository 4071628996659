import React from 'react'
import { Box, Image } from 'rebass'
import Img from 'gatsby-image'
import ParallaxUpdate from '../ParallaxUpdate'

const ParallaxGatsbyImage = ({
  className, ml, mr, mx, image
}) => (
  <ParallaxUpdate>
    <Box ml={ml} mr={mr} mx={mx}>
      {image.fluid
        ? <Img alt={image.description} className={className} fluid={{ ...image.fluid }} />
        : <Image alt={image.description} className={className} src={image.file.url} />
        }
    </Box>
  </ParallaxUpdate>
)

export default ParallaxGatsbyImage
