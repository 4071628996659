import React from 'react'
import PropTypes from 'prop-types'
import StyledServicesList from './style'

const ServicesList = ({ services }) => (
  <StyledServicesList
    mb={[6, 6, 6, 0]}
    dangerouslySetInnerHTML={{ __html: services }}
  />
)

ServicesList.propTypes = {
  services: PropTypes.string.isRequired
}

export default ServicesList
