import React from 'react'
import Img from 'gatsby-image'
import StyleDropShadowGatsbyImage from './style'

const DropShadowGatsbyImage = ({
  alt, className, fluid, white, whitelarge, ml, mr, mx
}) => (
  <StyleDropShadowGatsbyImage white={white} whitelarge={whitelarge} ml={ml} mr={mr} mx={mx}>
    <Img
      alt={alt}
      className={className}
      fluid={{
        ...fluid
      }}
    />
  </StyleDropShadowGatsbyImage>
)

export default DropShadowGatsbyImage
