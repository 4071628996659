import styled from 'styled-components'
import { Text } from 'rebass'

const StyledLiveUrl = styled(Text)`
  p {
    margin: 0;

    a {
      color: #000;
      display: block;
      line-height: 1.75rem;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    img {
      display: block;
      margin: 15px 0;
    }
  }
`

export default StyledLiveUrl
