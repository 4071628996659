import React from 'react'
import PropTypes from 'prop-types'
import { Box, Text } from 'rebass'
import Heading from '../../elements/Heading'
import StyledQuickFacts from './style'

const QuickFacts = ({ quickFactDetail, quickFactTitle }) => (
  <StyledQuickFacts flexDirection="column" justifyContent="center">
    {quickFactTitle && (
    <Box>
      <Heading is="h2" fontSize={[4, 5, 5, 5]} postsymbol="" color="#fff">{quickFactTitle}</Heading>
    </Box>
    )}
    <Box>
      <Text fontSize={[1, 2, 2, 2]} fontWeight="700" my={0} px={2} dangerouslySetInnerHTML={{ __html: quickFactDetail }} />
    </Box>
  </StyledQuickFacts>
)

QuickFacts.propTypes = {
  quickFactDetail: PropTypes.string.isRequired,
  quickFactTitle: PropTypes.string.isRequired
}

export default QuickFacts
