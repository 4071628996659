import styled from 'styled-components'
import { Image } from 'rebass'
import StyledServicesList from '../CaseStudyServices/style'
import StyledChallengeBgImage from '../CaseStudyChallengeBg/style'
import StyledQuickFacts from '../CaseStudyQuickFacts/style'
import StyledSolutionBg from '../CaseStudySolutionBg/style'
import StyledSolutionContainer from '../CaseStudySolution/style'
import { StyledHeading } from '../../elements/Heading'
import BcGovCircle1 from '../../../../static/images/bcgov-case-study/undraw_lost_online_wqob.jpg'
import BcGovCircle2 from '../../../../static/images/bcgov-case-study/undraw_searching.jpg'
import BcGovCircle3 from '../../../../static/images/bcgov-case-study/undraw_vote.jpg'

const StyledCaseStudyPage = styled.div`
  overflow: hidden;

  ${Image}.whiteDropShadowLarge {
    filter: drop-shadow(0px 2px 80px #E3E3E3);
  }

  ${Image}.dropShadow {
    filter: drop-shadow(0px 20px 30px rgba(0, 0, 0, 0.2));
  }

  ${StyledHeading} {
    display: block;
  }

  .case-study-logo {
    @media (max-width: ${props => props.theme.breakpoints[1]}) {
      height: 75px;
    }

    img {
      max-height: 100%;
    }
  }

  .collage {
    overflow: visible !important;
  }

  .content {
    max-width: 1168px;
    overflow: visible;
    margin: 0 30px;

    @media (min-width: ${props => props.theme.breakpoints[3]}) {
      margin: 0 auto;
    }
  }

  h1 {
    font-size: 36px;
    letter-spacing: -3px;
    line-height: 46px;

    @media (min-width: ${props => props.theme.breakpoints[1]}) {
      font-size: 60px;
      letter-spacing: -3.5px;
      line-height: 70px;
    }
  }

  h2 {
    letter-spacing: -2px;

    @media (min-width: ${props => props.theme.breakpoints[1]}) {
      letter-spacing: -2.75px;
    }
  }

  h3 {
    letter-spacing: -1px;

    @media (min-width: ${props => props.theme.breakpoints[2]}) {
      letter-spacing: -2px;
    }
  }

  .teacher-image {
    display: block;
    max-width: 100%;
    height: 275px;
    margin-left: auto;
    position: absolute;
    right: 20px;
    top: -325px;

    @media (min-width: ${props => props.theme.breakpoints[2]}) {
      height: auto;
      right: -150px;
      top: -200px;
    }
  }

  .relative {
    position: relative;
  }

  &.a-say {
    ${StyledServicesList} {
      ul {
        column-count: 1;

        @media (min-width: ${props => props.theme.breakpoints[2]}) {
          column-count: 3;
        }
      }
    }

    h1 {
      font-size: 36px;
      line-height: 46px;

      @media (min-width: ${props => props.theme.breakpoints[1]}) {
        font-size: 57px;
        line-height: 67px;
      }
    }

    .hero {
      margin: auto;
      max-width: 587px;
    }

    .case-study-logo {
      @media (min-width: ${props => props.theme.breakpoints[1]}) {
        height: 102px;
      }
    }

    .collage,
    .challenge-wrapper,
    .live-url-wrapper,
    .solution-wrapper {
      display: none;
    }

    .services-wrapper {
      width: 80%;
    }
  }

  &.dc {
    ${StyledChallengeBgImage} {
      bottom: auto;
      min-width: 0;
      top: 300px;
      width: 100%;

      @media (min-width: ${props => props.theme.breakpoints[1]}) {
        top: 350px;
      }
    }

    ${StyledQuickFacts} {
      margin: 250px auto -450px;
      padding: 0;

      @media (min-width: ${props => props.theme.breakpoints[1]}) {
        margin: 36rem 0 -10rem auto;
      }

      @media (min-width: ${props => props.theme.breakpoints[2]}) {
        margin: 5rem 0 -10rem auto;

        p {
          font-size: 2.8125rem;
          font-weight: 900;
          line-height: 3.125rem;
          margin-bottom: 20px;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }

    .case-study-logo {
      @media (min-width: ${props => props.theme.breakpoints[1]}) {
        height: 85px;
      }
    }

    .challenge {
      margin-bottom: 0;

      @media (min-width: ${props => props.theme.breakpoints[2]}) {
        margin-bottom: 1000px;
      }
    }

    .collage {
      margin: 0 auto;
      max-width: 791px;

      img {
        filter: none;
      }
    }

    .hero {
      margin: auto;
      max-width: 584px;
    }

    .solution-1 {
      left: 75px;
      max-width: 20%;
      position: absolute !important;
      top: 100px;
      width: 100%;
      z-index: 2;

      @media (min-width: ${props => props.theme.breakpoints[1]}) {
        min-width: 88px;
        left: 150px;
        top: 175px;
      }

      @media (min-width: ${props => props.theme.breakpoints[2]}) {
        margin-left: auto;
        max-width: 200px;
        position: static !important;

        img {
          left: auto !important;
          max-width: 200px;
          right: 0;
        }
      }
    }

    .solution-1-plx {
      position: relative;
    }

    .solution-2-plx {
      @media (min-width: ${props => props.theme.breakpoints[2]}) {
        padding-top: 200px;
      }
    }

    .solution-2 {
      @media (min-width: ${props => props.theme.breakpoints[3]}) {
        min-width: 800px;
      }
    }
  }

  &.gw {
    ${StyledQuickFacts} {
      margin: 14rem auto -27rem;

      @media (min-width: ${props => props.theme.breakpoints[1]}) {
        margin: 0 0 0 auto;
      }

      @media (min-width: ${props => props.theme.breakpoints[2]}) {
        margin: 14rem 0 -11rem auto;
      }
    }

    ${StyledSolutionContainer} {
      margin-top: 0;
      padding-top: 650px;

      @media (min-width: ${props => props.theme.breakpoints[0]}) {
        p {
          padding-right: 200px;
        }
      }

      @media (min-width: ${props => props.theme.breakpoints[1]}) {
        padding-top: 390px;

        p {
          padding-right: 250px;
        }
      }

      @media (min-width: ${props => props.theme.breakpoints[2]}) {
        margin-top: 500px;
        padding-top: 460px;

        p {
          padding-right: 0;
        }
      }

      @media (min-width: ${props => props.theme.breakpoints[3]}) {

      }
    }

    .case-study-logo {
      @media (min-width: ${props => props.theme.breakpoints[1]}) {
        height: 126px;
      }
    }

    .challenge-bg {
      min-width: 130%;
      left: calc(50% - 15px);
      bottom: -275px;
      transform: translateX(-50%);

      @media (min-width: 321px) {
        bottom: -300px;
      }

      @media (min-width: 415px) {
        min-width: 100%;
      }

      @media (min-width: ${props => props.theme.breakpoints[1]}) {
        bottom: -200px;
      }

      @media (min-width: ${props => props.theme.breakpoints[2]}) {
        bottom: auto;
        min-width: 107.5%;
        top: -75px;
      }
    }

    .collage {
      overflow: visible !important;
      img {
        filter: none;
      }
    }

    .hero {
      margin: auto;
      max-width: 800px;
    }

    .solution-1-plx {
      position: relative;
    }

    .solution-2-plx {
      @media (min-width: ${props => props.theme.breakpoints[2]}) {
        padding-top: 200px;
      }
    }

    .solution-1 {
      left: 50px;
      max-width: 30%;
      position: absolute !important;
      min-width: 78px;
      top: 100px;
      width: 100%;
      z-index: 2;

      @media (min-width: ${props => props.theme.breakpoints[1]}) {
        left: 125px;
        top: 175px;
      }

      @media (min-width: ${props => props.theme.breakpoints[2]}) {
        bottom: auto;
        margin-left: auto;
        position: static !important;
        max-width: 250px;
        right: auto;
        top: 0;
        right: 0;

        img {
          left: auto !important;
          max-width: 250px;
          right: 0;
        }
      }
    }

    .solution-2 {
      img {
        height: auto !important;
      }
    }
  }

  &.mnp {
    ${StyledChallengeBgImage} {
      bottom: 0px;
      min-width: auto;
      right: calc(50% - 584px);
    }

    ${StyledQuickFacts} {
      margin: 31rem auto -45rem;
      padding: 0;

      @media (min-width: ${props => props.theme.breakpoints[1]}) {
        margin: 32rem auto -55rem;

        p {
          font-size: 2.8125rem;
          font-weight: 900;
          line-height: 3.125rem;
          margin-bottom: 20px;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }

      @media (min-width: ${props => props.theme.breakpoints[2]}) {
        margin: 23rem 0 -15rem calc(100% - 416px);
      }
    }

    ${StyledSolutionBg} {
      top: 0;
    }

    ${StyledSolutionContainer} {
      padding-top: 425px;

      @media (min-width: ${props => props.theme.breakpoints[2]}) {
        padding-top: 525px;
      }
    }

    .case-study-logo {
      height: 37px;
    }

    .challenge {
      flex-direction: column-reverse;

      @media (min-width: ${props => props.theme.breakpoints[2]}) {
        flex-direction: row;
      }
    }

    .collage {
      margin: 0 auto;
      max-width: 996px;
    }

    .solution-1 {
      margin-left: auto;

      @media (min-width: ${props => props.theme.breakpoints[2]}) {
        margin-top: 110px;
      }
    }

    .solution-2 {
      filter: drop-shadow(0px 20px 30px rgba(0, 0, 0, 0.2));
    }

    @media (max-width: ${props => props.theme.breakpoints[2]}) {
      .challenge-bg {
        margin-bottom: 64px;
        min-width: 0;
        position: static;
        width: 100%;
      }

      .quick-facts {
        height: 220px;
        margin: 50px calc(50% - 75px) -850px;
        width: 220px;
      }

      .solution-1 {
        bottom: 0;
        max-width: 150px;
        position: absolute;
        z-index: -1;
      }

      .solution-2 {
        filter: drop-shadow(0px 20px 30px rgba(0, 0, 0, 0.2));
        padding-bottom: 80px;
        padding-top: 0;
      }
    }

    @media (max-width: 374px) {
      .solution-1 {
        bottom: 0px;
        left: -20px;
        max-width: 11-0px;
        position: absolute;
        z-index: -1;
      }
    }
  }

  &.telus {
    ${StyledQuickFacts} {
      margin: 250px calc(60% - 110px) -500px;

      @media (min-width: ${props => props.theme.breakpoints[1]}) {
        margin: 18rem 0 -21rem auto;
      }

      @media (min-width: ${props => props.theme.breakpoints[2]}) {
        margin: 18rem 0 -16rem auto;
      }
    }

    .case-study-logo {
      height: 45px;
    }

    .challenge-bg {
      bottom: -775px;
      min-width: 800px;

      @media (min-width: ${props => props.theme.breakpoints[1]}) {
        min-width: 1000px;
        bottom: -575px;
      }
    }

    .hero {
      margin: auto;
      max-width: 533px;
    }

    .solution-1,
    .solution-2 {
      @media (min-width: ${props => props.theme.breakpoints[1]}) {
        max-width: 466px;
        left: 50% !important;
        transform: translateX(-50%);
      }
    }
  }

  &.bc-gov {
    h1 {
      font-size: ${({ theme }) => `${theme.fontSizes[4]}px`};
      line-height: 50px;
      font-weight: 900;
      letter-spacing: -1.5px;
      @media (min-width: ${props => props.theme.breakpoints[3]}) {
        font-size: ${({ theme }) => `${theme.fontSizes[5]}px`};
        line-height: 90px;
        letter-spacing: -3.5px;
      }
    }
    .hero-section {
      @media (max-width: ${props => props.theme.breakpoints[2]}) {
        margin-top: 110px;
        & > div:first-child {
          margin-bottom: 4px;
          border-right: ${({ theme }) => `2px dashed ${theme.colors.primary}`};
          width: 100%;
        }
      }
      @media (min-width: 420px) and (max-width: ${props => props.theme.breakpoints[2]}) {
        width: calc(100% - 12px);
      }
      @media (min-width: ${props => props.theme.breakpoints[2]}) {
        align-items: stretch;
        & > div:last-child {
          height: 100%;
          align-self: flex-end;
        }
      }
    }

    .hero-section-image-container {
      margin-bottom: 0;
      height: 100%;
    }
    .hero-section-copy {
      @media (max-width: ${props => props.theme.breakpoints[2]}) {
        padding-right: 16px;
      }
    }
    .case-study-logo {
      transform: translateX(-22px);
      margin-bottom: 14px;
    }
    .hero-collage {
      position: relative;
      margin-top: 24px;
      margin-bottom: -35px;
      height: 100%;
      @media (max-width: ${props => props.theme.breakpoints[2]}) {
        margin-left: auto;
        margin-bottom: -5px;
        max-width: 565px;
        transform: translateX(-16px);
        width: calc(100% + 10px);
      }
      &:after {
        content: '!';
        position: absolute;
        z-index: 2;
        bottom: -23px;
        right: -15px;
        height: 78px;
        width: 78px;
        vertical-align: center;
        border-radius: 50%;
        text-align: center;
        font-size: 60px;
        line-height: 78px;
        font-weight: 900;
        color: #fff;
        background-color: ${({ theme }) => theme.colors.primary};
        box-shadow: 0px 12px 18px rgba(0, 0, 0, 0.2);
        @media (min-width: ${props => props.theme.breakpoints[2]}) {
          bottom: 0;
          right: 0;
        }
      }
      @media (min-width: ${props => props.theme.breakpoints[2]}) {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        &:after {
          content: none;
        }
      }
    }
    .hero-collage-item-1 {
      margin-bottom: 12px;
      margin-left: auto;
      width: 86%;
      max-width: 502px;
    }
    .hero-collage-item-2 {
      position: absolute !important;
      top: 39%;
      width: 80%;
      max-width: 467px;
      z-index: 2;
    }
    .hero-collage-item-3 {
      margin-left: auto;
      width: 46%;
      max-width: 269px;
      transform: translateX(21px);
      z-index: -1;
    }
    .services-section {
      margin-top: 0;
      @media (min-width: ${props => props.theme.breakpoints[2]}) and (max-width: ${props => props.theme.breakpoints[3]}) {
        padding-top: 4rem;
      }
    }
    .services-and-outcomes-wrapper {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        height: calc(100% - 115px);
        width: calc(100% - 2px);
        border-right: ${({ theme }) => `2px dashed ${theme.colors.primary}`};
        border-bottom: ${({ theme }) => `2px dashed ${theme.colors.primary}`};
      }
      @media (min-width: 420px) {
        width: calc(100% - 14px);
      }
      @media (min-width: ${props => props.theme.breakpoints[2]}) {
        &:before {
          content: '!';
          position: absolute;
          z-index: 2;
          top: -39px;
          right: 72px;
          height: 78px;
          width: 78px;
          border-radius: 50%;
          text-align: center;
          font-size: 60px;
          line-height: 78px;
          font-weight: 900;
          color: #fff;
          background-color: ${({ theme }) => theme.colors.primary};
          box-shadow: 0px 12px 18px rgba(0, 0, 0, 0.2);
        }
        &:after {
          width: calc(100% - 112px);
        }
      }
    }
    .services-wrapper {
      margin-top: 24px;
    }
    .desired-outcome-section {
      position: relative;
      margin-bottom: 0;
      z-index: 2;
      padding-left: 16px;
      padding-right: 16px;
      background-color: #fff;
      @media (max-width: ${props => props.theme.breakpoints[2]}) {
        margin-left: 24px;
        margin-right: 24px;
        width: calc(100% - 48px);
      }
      p {
        margin-bottom: 0;
      }
    }
    .bcgov-circles {
      position: relative;
      padding: 124px 72px 0;
      @media (min-width: ${props => props.theme.breakpoints[2]}) {
        padding: 240px 72px 0;
        margin-bottom: -85px;
        z-index: 1;
      }
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: -115px;
        width: 50%;
        height: 200px;
        border-left: ${({ theme }) => `2px dashed ${theme.colors.primary}`};
        border-bottom: ${({ theme }) => `2px dashed ${theme.colors.primary}`};
        z-index: -1;
        @media (min-width: ${props => props.theme.breakpoints[2]}) {
          height: auto;
          bottom: 110px;
          width: calc(100% - 222px);
        }
        @media (min-width: 1180px) {
          bottom: 150px;
        }
      }
      &:after {
        content: '!';
        position: absolute;
        z-index: 2;
        left: -1rem;
        top: 46px;
        height: 78px;
        width: 78px;
        border-radius: 50%;
        text-align: center;
        font-size: 60px;
        line-height: 78px;
        font-weight: 900;
        color: #fff;
        background-color: ${({ theme }) => theme.colors.primary};
        box-shadow: 0px 12px 18px rgba(0, 0, 0, 0.2);
        @media (min-width: ${props => props.theme.breakpoints[2]}) {
          top: auto;
          bottom: 71px;
          left: -24px;
        }
        @media (min-width: 1180px) {
          left: -39px;
          bottom: 111px;
        }
      }
    }
    .bcgov-circles-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      &:after {
        content: '';
        position: absolute;
        top: 85px;
        bottom: 0;
        left: calc(50% + 2px);
        border-left: ${({ theme }) => `2px dashed ${theme.colors.primary}`};
        z-index: -1;
      }
      @media (min-width: ${props => props.theme.breakpoints[2]}) {
        flex-direction: row;
        justify-content: space-between;
        &:after {
          content: none;
        }
      }
    }
    .bcgov-circles-item {
      margin-bottom: 2rem;
      border: 10px solid #0060FF;
      border-radius: 50%;
      height: 200px;
      width: 200px;
      background-color: #fff;
      background-size: cover;
      @media (min-width: ${props => props.theme.breakpoints[2]}) {
        margin-bottom: 0;
      }
      @media (min-width: 1180px) {
        height: 280px;
        width: 280px;
      }
    }
    .bcgov-circles-item-1 {
      background-image: url(${BcGovCircle1});
    }
    .bcgov-circles-item-2 {
      background-image: url(${BcGovCircle2});
    }
    .bcgov-circles-item-3 {
      background-image: url(${BcGovCircle3});
    }
    .solution-gallery {
      position: relative;
      @media (min-width: ${props => props.theme.breakpoints[2]}) {
        margin-bottom: -835px;
        &:after {
          content: '';
          position: absolute;
          top: 85px;
          right: 182px;
          height: 350px;
          border-left: ${({ theme }) => `2px dashed ${theme.colors.primary}`};
        }
      }
      @media (min-width: 1180px) {
        &:after {
          right: 222px;
        }
      }
      @media (min-width: ${props => props.theme.breakpoints[3]}) {
        margin-bottom: -480px;
      }
    }
    .solution-gallery-container {
      position: relative;
      width: 100vw;
      margin-left: 50%;
      transform: translateX(-50%);
      background: linear-gradient(70deg, #1440f9, #8C65F6);
      @media (min-width: ${props => props.theme.breakpoints[2]}) {
        background: linear-gradient(90deg, #1440f9, #c02f9a);
      }
      &:after {
        content: '';
        position: absolute;
        top: 4px;
        left: calc(50% + 2px);
        height: 104px;
        border-left: ${({ theme }) => `2px dashed ${theme.colors.primary}`};
        z-index: 2;
        @media (min-width: ${props => props.theme.breakpoints[2]}) {
          content: none;
        }
      }
    }
    .solution-gallery-upper {
      border-width: 0 100vw 216px 0;
      border-color: transparent #fff transparent transparent;
      border-style: solid;
      @media (min-width: ${props => props.theme.breakpoints[2]}) {
        border-bottom-width: 835px;
      }
    }
    .solution-gallery-copy-container {
      margin: 0 30px;
      max-width: 1168px;
      @media (min-width: ${props => props.theme.breakpoints[2]}) {
        margin: -505px 30px -48px;
        transform: translateY(-48px);
      }
      @media (min-width: ${props => props.theme.breakpoints[3]}) {
        margin-left: auto;
        margin-right: auto;
      }
      @media (min-width: 1380px) {
        transform: translateY(0);
      }
      @media (min-width: 2050px) {
        transform: translateY(50px);
      }
      @media (min-width: 2700px) {
        transform: translateY(100px);
      }
    }
    .solution-gallery-copy {
      @media (min-width: ${props => props.theme.breakpoints[2]}) {
        width: 37%;
      }
    }
    .solution-gallery-lower {
      border-width: 216px 0 0 100vw;
      border-color: transparent transparent transparent #fff;
      border-style: solid;
      @media (min-width: ${props => props.theme.breakpoints[2]}) {
        border-top-width: 835px;
      }
    }
    .solution-gallery-images-container {
      @media (min-width: ${props => props.theme.breakpoints[2]}) {
        position: absolute;
        width: 63%;
        right: 0;
        top: 430px;
      }
    }
    .solution-gallery-images {
      position: relative;
      z-index: 2;
      margin-top: -200px;
      @media (min-width: ${props => props.theme.breakpoints[2]}) {
        margin-top: 0;
      }
    }
    .solution-gallery-item-1 {
      margin-left: auto;
      margin-bottom: -18px;
      width: 73%;
      max-width: 541px;
      transform: translateX(18px);
    }
    .solution-gallery-item-2 {
      position: absolute !important;
      top: 30%;
      width: 80%;
      max-width: 596px;
      z-index: 2;
    }
    .solution-gallery-item-3 {
      margin-left: auto;
      width: 52%;
      max-width: 391px;
    }
  }

  [data-aos="fade-in-load"] {
    opacity: 0;
    transition-delay: 0.2s;
    transition-duration: 1s;

    &.aos-animate {
      opacity: 1;
    }
  }

  @media (max-width: 767px) {
    [data-aos="fade-in-load"] {
      opacity: 0;
      transition-delay: 0;
      transition-duration: 0.5s;

      &.aos-animate {
        opacity: 1;
      }
    }
  }
`

export default StyledCaseStudyPage
