import styled, { css } from 'styled-components'
import { Box } from 'rebass'

const StyledDropShadowGatsbyImage = styled(Box)`
  img {
    filter: drop-shadow(0px 20px 30px rgba(0, 0, 0, 0.2));
    transform: translateZ(0);
    perspective: 1000px;
    backface-visibility: hidden;

    ${props => props.white && css`
      filter: drop-shadow(0px 1px 39px #E3E3E3);
    `}

    ${props => props.whitelarge && css`
      filter: drop-shadow(0px 2px 80px #E3E3E3);
    `}
  }
`

export default StyledDropShadowGatsbyImage
