import styled from 'styled-components'

const StyledChallengeBgImage = styled.div`
  bottom: -575px;
  right: 0px;
  position: absolute;
  width: auto;
  z-index: -2;
`

export default StyledChallengeBgImage
