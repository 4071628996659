import { useEffect } from 'react'
import { withController } from 'react-scroll-parallax'
import PropTypes from 'prop-types'

const ParallaxUpdate = ({ children, parallaxController, location }) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      parallaxController.update()
    }, 500)
    return () => clearTimeout(timeout)
  }, [parallaxController, location])

  return children
}

ParallaxUpdate.defaultProps = {
  location: undefined
}

ParallaxUpdate.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object,
  parallaxController: PropTypes.object.isRequired
}

export default withController(ParallaxUpdate)
